import $ from 'jquery';
import '../jquery-context-menu/jquery-context-menu';
import { insertTextAtCursor, getTextToSaveAsMacro } from './plain-text-context-menu-helpers';
import { 
  generateAllergyText,
  generateFamilyHistoryText,
  generateMedicationText,
  generatePastMedicalHistoryText,
  generateSocialHistoryText 
} from './generate-text-helpers';
import { generatePlainTextTextareaSelector } from '../macro-text-helpers';
import { replaceSelectedTextInRichTextEditor } from '../../../../../components/shared_ui/rich_textbox/editor_helpers';

export class MacroContextMenu {

  init() {
    fetchMacros();
    setupContextMenu(defaultMacroContextMenuInitOptions());
  }
}

export function initMacroContextMenu() {
  window.addEventListener('DOMContentLoaded', (() => {
    if ($('.load-user-macros').length) {
      getEmployeeMacros()
    }
    window.MacroContextMenu = new MacroContextMenu();
  }))
}

const setAutosize = () => {
  autosize($('textarea'));
  setTimeout(() => {
    autosize.update($('textarea'));
  }, 300);
}

const defaultMacroContextMenuInitOptions = () => ({
  isRichTextEditor: false,
  textboxElementSelectorString: generatePlainTextTextareaSelector(),
  insertTextAtCursor,
  getTextToSaveAsMacro
})


function altKeyTriggerFn (event) {
  if (event.altKey) {
    $(event.target).contextMenu({ x: event.pageX, y: event.pageY });
    event.preventDefault();
  }
}
// TODO: test if this works
export const altKeyTrigger = (jQuerySelector) => {
  $(jQuerySelector).off('contextmenu', altKeyTriggerFn);
  $(jQuerySelector).on('contextmenu', altKeyTriggerFn);
}

export const setupContextMenuForRichTextEditor = (editor) => {
  setupContextMenu({
    ...defaultMacroContextMenuInitOptions(),
    isRichTextEditor: true,
    textboxElementSelectorString: '.ava-rich-textbox__content-editable',
    insertTextAtCursor: ({ text }) => {
      replaceSelectedTextInRichTextEditor(text, editor);
    },
    getTextToSaveAsMacro: () => editor.state.doc.textBetween(
      editor.state.selection.from,
      editor.state.selection.to
    )
  })
}

const setupContextMenu = (options) => {
  $.contextMenu('destroy', options.textboxElementSelectorString);
  altKeyTrigger(options.textboxElementSelectorString);
  $.contextMenu({
    selector: options.textboxElementSelectorString,
    trigger: 'none',
    callback: function(itemKey, opt, rootMenu, originalEvent) {
      // This callback is run when a macro is selected from the context menu
      options.insertTextAtCursor({ opt, text: opt.commands[itemKey].content});
    },
    items: {
      timestamp: {
        name: 'Time Now',
        icon: 'fa-clock-o',
        callback: function(itemKey, opt, rootMenu, originalEvent) {
          options.insertTextAtCursor({ opt, text: moment().format('LTS')});
        }
      },
      datestamp: {
        name: 'Date Now',
        icon: 'fa-calendar-o',
        callback: function(itemKey, opt, rootMenu, originalEvent) {
          options.insertTextAtCursor({ opt, text: moment().format('DD-MMM-YYYY')});
        }
      },
      histories: {
        name: 'Histories',
        icon: 'fa-user-md',
        items: {
          past_medical_histories: {
            name: 'Medical History',
            icon: 'fa-user-md',
            callback: function(itemKey, opt, rootMenu, originalEvent) {
              const patientId = $('.load-user-macros').data('patient-id');
              $.ajax({
                url: `/patients/${patientId}/context_menu/past_medical_histories`,
                dataType: 'json',
                success: function(data) {
                  options.insertTextAtCursor({ opt, text: generatePastMedicalHistoryText(data)});
                }
              });
            }
          },
          social_histories: {
            name: 'Social History',
            icon: 'fa-sign-language',
            callback: function(itemKey, opt, rootMenu, originalEvent) {
              const patientId = $('.load-user-macros').data('patient-id');
              $.ajax({
                url: `/patients/${patientId}/context_menu/social_histories`,
                dataType: 'json',
                success: function(data) {
                  options.insertTextAtCursor({ opt, text: generateSocialHistoryText(data)});
                }
              });
            }
          },
          family_histories: {
            name: 'Family History',
            icon: 'fa-users',
            callback: function(itemKey, opt, rootMenu, originalEvent) {
              const patientId = $('.load-user-macros').data('patient-id');
              $.ajax({
                url: `/patients/${patientId}/context_menu/family_histories`,
                dataType: 'json',
                success: function(data) {
                  options.insertTextAtCursor({ opt, text: generateFamilyHistoryText(data)});
                }
              });
            }
          },
          medications: {
            name: 'Medication',
            icon: 'fa-toggle-on',
            callback: function(itemKey, opt, rootMenu, originalEvent) {
              const patientId = $('.load-user-macros').data('patient-id');
              $.ajax({
                url: `/patients/${patientId}/context_menu/medications`,
                dataType: 'json',
                success: function(data) {
                  options.insertTextAtCursor({ opt, text: generateMedicationText(data)});
                }
              });
            }
          },
          allergies: {
            name: 'Allergy',
            icon: 'fa-bell-o',
            callback: function(itemKey, opt, rootMenu, originalEvent) {
              const patientId = $('.load-user-macros').data('patient-id');
              $.ajax({
                url: `/patients/${patientId}/context_menu/allergies`,
                dataType: 'json',
                success: function(data) {
                  options.insertTextAtCursor({ opt, text: generateAllergyText(data)});
                }
              });
            }
          }
        }
      },
      sep2: '---------',
      macros: {
        name: 'Macros',
        icon: 'fa-cubes',
        items: JSON.parse(localStorage.getItem('contextMenuItems'))
      },
      sep3: '---------',
      save_selected: {
        name: 'Save Selected as Macro',
        icon: 'fa-save',
        callback: function(itemKey, opt) {
          saveTextAsMacro(options.getTextToSaveAsMacro(opt));
        }
      },
      sep4: '--------',
      ai_assist: {
        name: 'AI Assist',
        icon: 'fa-cogs',
        callback: function(itemKey, opt) {
          fetchAiAssist(opt)
        },
        visible: function() {
          return $(options.textboxElementSelectorString).data('assist-enabled') === true;
        }
      }
    }
  });
  if (!options.isRichTextEditor) {
    setAutosize();
  }
}

// Make it so that you instantiate a stateful object that can be used store logic for the callback functions
// ie. callback: function(itemKey, opt, rootMenu, originalEvent) { obj['timeNow']() }

const fetchMacros = () => {
  if ($('.load-user-macros').length) {
    getEmployeeMacros().done(function(data) {
      localStorage.setItem('keyShortcuts', JSON.stringify(data.keyShortcuts))
      localStorage.setItem('contextMenuItems', JSON.stringify(data.contextMenu))
      setupContextMenu(defaultMacroContextMenuInitOptions());
    });
  }
}

const saveTextAsMacro = (text) => {
  const user_id = $('.load-user-macros').data('user-id');
  const employee_id = $('.load-user-macros').data('employee-id');
  $.ajax({
    url: `/account_management/users/${user_id}/employees/${employee_id}/macros/new`,
    data: { macro: { content: text } },
    dataType: 'script'
  });
}

const fetchAiAssist = (opt) => {
  // Note: the nonce is used for choosing with LLM channel to subscribe to
  // However, to ensure the target ID is unique - Assist also sets the
  // field.id to the nonce
  const field = opt.$trigger[0];
  const category = field.getAttribute('data-ai-assist-category');
  const patientSgid = field.getAttribute('data-patient-sgid');
  const nonce = crypto.randomUUID();
  field.setAttribute('data-ai-assist-nonce', nonce);
  field.setAttribute('id', nonce);

  $.ajax({
    url: `/ai_assists/new`,
    data: { nonce: nonce, category: category, patient_sgid: patientSgid },
    dataType: 'script'
  });
}

const getEmployeeMacros = () => {
  const user_id = $('.load-user-macros').data('user-id');
  const employee_id = $('.load-user-macros').data('employee-id');
  return $.ajax({
    url: `/account_management/users/${user_id}/employees/${employee_id}/macros`,
    dataType: 'json'
  });

}